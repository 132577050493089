import React from 'react';
import './intro.css';
import { Link } from 'react-scroll'

const Intro = () => {

  const handleDownloadCV = () => {
    // Modifier le chemin selon votre arborescence
    const pdfPath = '/CV.pdf';
    window.open(pdfPath, '_blank');
  };

  return (
    <div id='intro' className='introContent text-center'>
        <h1 className='name'>Arnaud Meusy</h1><br/>
        <h2 className='title'>Développeur Web</h2>
        <p className='introPar'>Développeur web, je conçois des sites web modernes, efficaces et adaptés à vos besoins.</p>
        
        <Link activeClass='active' to='contact' spy={true} smooth={true} offset={-75} duration={100}><button className='btnContact mt-3'>Contactez-moi</button></Link>
        {/* <Link activeClass='active' to='about' spy={true} smooth={true} offset={-100} duration={100}><button className='btnContact mt-3'>À propos de moi</button></Link> */}
        {/* <button onClick={handleDownloadCV} className='btnContact mt-3'>Télécharger CV</button> */}
    
    </div>
  )
}

export default Intro