import React from 'react'
import './portfolio.css'
import FlorentEspana from '../../images/FlorentEspana.png'
import EightyOne from '../../images/EightyOne.png'
import Concrete from '../../images/concrete.png'
import ClubAuto from '../../images/ClubAuto.png'
import Easyloc from '../../images/Easyloc.png'
import barma from '../../images/barma.png'
import grandspins from '../../images/grandspins.png'
import brocante from '../../images/brocante.png'
import mouve from '../../images/mouve.png'
import leasing from '../../images/leasing.png'


const Portfolio = () => {
    return (
        <section className='portfolio'>
            <p className='portFolioTitle text-center'>Port<span className='competences'>folio</span></p>

            <div class="portFolio-container">
                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.florent-espana.com" target="_blank" rel="noopener noreferrer">
                        Florent Espana</a>
                    <div className='portFolio-content'>
                        <a href="https://www.florent-espana.com" target="_blank" rel="noopener noreferrer">
                            <img src={FlorentEspana} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Réalisation d'un site web vitrine et de e-commerce pour l'artiste peintre Florent
                                Espana. Création d'une plateforme permettant à l'artiste de présenter ses
                                oeuvres et de vendre ses peintures en ligne.</p>
                            <p className='techno'>- Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://eightyonestore.com/" target="_blank" rel="noopener noreferrer">
                        Eighty One Store</a>
                    <div className='portFolio-content'>
                        <a href="https://eightyonestore.com/" target="_blank" rel="noopener noreferrer">
                            <img src={EightyOne} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Gestion et maintenance du site e-commerce 81 Store, boutique spécialisée dans la vente de produits pour le graffiti et les arts graphiques.</p>
                            <p className='techno'>- Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.barma-residence-courchevel.fr/" target="_blank" rel="noopener noreferrer">
                    Résidence Barma Courchevel</a>
                    <div className='portFolio-content'>
                        <a href="https://www.barma-residence-courchevel.fr/" target="_blank" rel="noopener noreferrer">
                            <img src={barma} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site vitrine de la résidence Barma, un complexe d’appartements de luxe situé à Courchevel au cœur des 3 Vallées. Ce projet visait à valoriser les prestations haut de gamme et l’expérience unique qu’offre cette résidence à Courchevel La Tania.</p>
                            <p className='techno'>- PHP, HTML, SCSS, Javascript</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://sccvlesgrandspins.fr" target="_blank" rel="noopener noreferrer">
                    Les Grands Pins</a>
                    <div className='portFolio-content'>
                        <a href="https://sccvlesgrandspins.fr" target="_blank" rel="noopener noreferrer">
                            <img src={grandspins} alt='les grands pins' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site vitrine des résidences Les Grands Pins, demeures d'exception situées à Saint-Rémy-de-Provence. Ce projet avait pour objectif de valoriser l'architecture provençale haut de gamme, le confort moderne et l’art de vivre dans cette région emblématique.
                            </p>
                            <p className='techno'>- PHP, HTML, SCSS, Javascript</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.brocante-du-moulin.fr" target="_blank" rel="noopener noreferrer">
                    Brocante du Moulin</a>
                    <div className='portFolio-content'>
                        <a href="https://www.brocante-du-moulin.fr" target="_blank" rel="noopener noreferrer">
                            <img src={brocante} alt='les grands pins' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site d'une entreprise spécialisée dans le débarras rapide et écoresponsable en région Rhône-Alpes. Ce projet visait à présenter les services proposés de manière claire et attractive, tout en mettant en avant l'engagement environnemental et la simplicité du processus pour les clients.
                            </p>
                            <p className='techno'>- PHP, HTML, SCSS, Javascript</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.leasing-occasion.fr" target="_blank" rel="noopener noreferrer">
                    L'agence du leasing</a>
                    <div className='portFolio-content'>
                        <a href="https://www.leasing-occasion.fr" target="_blank" rel="noopener noreferrer">
                            <img src={leasing} alt='les grands pins' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Landing page dédié à la promotion du leasing automobile pour des véhicules d’occasion.
                            </p>
                            <p className='techno'>- PHP, HTML, SCSS, Javascript</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://www.mouve-clermont.com" target="_blank" rel="noopener noreferrer">
                    Résidence Mouve Clermont</a>
                    <div className='portFolio-content'>
                        <a href="https://www.mouve-clermont.com" target="_blank" rel="noopener noreferrer">
                            <img src={mouve} alt='les grands pins' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site promotionnel pour le programme immobilier Mouve à Clermont-Ferrand, une résidence éco-performante au cœur d’un nouveau quartier. Le projet met l'accent sur l'accessibilité à la propriété, la qualité de vie et les équipements modernes offerts aux futurs résidents.
                            </p>
                            <p className='techno'>- PHP, HTML, SCSS, Javascript</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://concrete-jungle.vercel.app" target="_blank" rel="noopener noreferrer">
                        Concrete Jungle</a>
                    <div className='portFolio-content'>
                        <a href="https://concrete-jungle.vercel.app" target="_blank" rel="noopener noreferrer">
                            <img src={Concrete} alt='site de concrete jungle festival' className='concreteImg' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Création d'une application web mobile headless pour la société fictive
                                d'évènements "Live Events" à l'occasion du festival de musique Concrete
                                Jungle Festival. Développement du frontend avec React via le framework Next.js et du backend avec
                                Wordpress.</p>
                            <p className='techno'>- React, Next.js, HTML, CSS, Wordpress, WooCommerce, PHP et MySQL</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://arnaudm1983.github.io/ClubAutoBootstrap/" target="_blank" rel="noopener noreferrer">
                        Club Auto</a>
                    <div className='portFolio-content'>
                        <a href="https://arnaudm1983.github.io/ClubAutoBootstrap/" target="_blank" rel="noopener noreferrer">
                            <img src={ClubAuto} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Site web vitrine réalisé pour Club Auto, une entreprise fictive spécialisée dans
                                la vente et la location de voitures. Création d’une interface moderne et
                                responsive, mettant en valeur les services et produits de l'entreprise.
                                Développement démontrant la capacité à intégrer des designs tout en assurant
                                une expérience utilisateur fluide.</p>
                            <p className='techno'>- HTML, CSS, Bootstrap</p>
                        </div>
                    </div>
                </div>

                <div className='portFolio-item'>
                    <a className='portFolio-link' href="https://github.com/ArnaudM1983/easyloc" target="_blank" rel="noopener noreferrer">
                        Easyloc'</a>
                    <div className='portFolio-content'>
                        <a href="https://github.com/ArnaudM1983/easyloc" target="_blank" rel="noopener noreferrer">
                            <img src={Easyloc} alt='site de florent espana' className='portFolioImage' />
                        </a>
                        <div className='portFolio-text'>
                            <p>Développement d'une bibliothèque d'accès aux données pour EasyLoc', société fictive de location de voitures. Assurant documentation, sécurité et tests unitaires. Utilisation de Symfony pour faciliter l'interaction avec les bases SQL et NoSQL.</p>
                            <p className='techno'>- Symfony, PHP, MySQL</p>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default Portfolio