import React from 'react'
import './competences.css'
import html from '../../images/html.png'
import css from '../../images/css.png'
import javascript from '../../images/javascript.png'
import bootstrap from '../../images/bootstrap.png'
import react from '../../images/react.png'
import wordpress from '../../images/wordpress.png'
import sql from '../../images/sql.png'
import php from '../../images/php.png'
import symfony from '../../images/symfony.png'

const Competences = () => {

    const handleDownloadCV = () => {
        // Modifier le chemin selon votre arborescence
        const pdfPath = '/CV.pdf';
        window.open(pdfPath, '_blank');
      };

  return (
    <section className='skills'>
        <p className='skillsTitle text-center'>Mes <span className='competences'>compétences</span></p>

        <div class="skills-container">
        <div class="skills-item">
            <div class="skills-header">
                <h2 className='text-center skillsH'>Frontend</h2>
            </div>
            <ul class="skills-list">
                <li class="skills-list-item">
                    <img src={html} alt='logo html' className='skillListImg' />
                    <p>HTML</p>
                </li>
                <li class="skills-list-item">
                    <img src={css} alt='logo html' className='skillListImg' />
                    <p>CSS</p>
                </li>
                <li class="skills-list-item">
                    <img src={javascript} alt='logo html' className='skillListImg' />
                    <p>Javascript</p>
                </li>
                <li class="skills-list-item">
                    <img src={bootstrap} alt='logo html' className='skillListImg' />
                    <p>Bootstrap</p>
                </li>
                <li class="skills-list-item">
                    <img src={react} alt='logo html' className='skillListImg' />
                    <p>React</p>
                </li>
                <li class="skills-list-item">
                    <img src={wordpress} alt='logo html' className='skillListImg' />
                    <p>Wordpress</p>
                </li>
            </ul>
        </div>
        <div class="skills-item">
            <div class="skills-header">
                <h2 className='text-center skillsH'>Backend</h2>
            </div>
            <ul class="skills-list">
                <li class="skills-list-item">
                    <img src={sql} alt='logo html' className='skillListImg' />
                    <p>SQL</p>
                </li>
                <li class="skills-list-item">
                    <img src={php} alt='logo html' className='skillListImg' />
                    <p>PHP</p>
                </li>
                <li class="skills-list-item">
                    <img src={symfony} alt='logo html' className='skillListImg' />
                    <p>Symfony</p>
                </li>
            </ul>
        </div>
    </div>
    </section>
  )
}

export default Competences